<template>
  <div class="page-common-body login-page-container">
    <div class="form-container">
      <div class="form-close" @click="onClose" v-if="isShowClose"></div>
      <div class="form-title">
        <template v-if="$bowser.mobile">
          <router-link
            tag="div"
            class="title__on-close"
            :to="{
              path: '/'
            }"
          ></router-link>
          <p class="title__text">{{ title[innerTheme].cn }}</p>
        </template>
        <template v-else>
          <p>{{ title[innerTheme].en }}</p>
          |
          <p>{{ title[innerTheme].cn }}</p>
        </template>
      </div>
      <div class="form-logo" v-if="$bowser.mobile"></div>
      <login-form
        v-if="innerTheme === 'login' || innerTheme === 'register'"
        @close="onClose"
        @showResetForm="showResetForm"
      ></login-form>
      <!-- <register-form
        v-else-if="innerTheme === 'register'"
        @close="onClose"
      ></register-form> -->
      <wx-scan
        v-else-if="innerTheme === 'wx-login'"
        @close="onClose"
        :code="code"
      ></wx-scan>
      <password-form
        v-else-if="innerTheme === 'password-login'"
        @close="onClose"
      ></password-form>
      <reset-password-form
        v-else-if="innerTheme === 'reset-password'"
      ></reset-password-form>
      <div
        class="other-login-ways"
        v-if="['login', 'wx-login', 'password-login'].includes(innerTheme)"
      >
        <div class="way__title" v-if="$bowser.mobile">
          <p>其他登录</p>
        </div>
        <div class="way__item-wrapper">
          <div
            class="way__item"
            v-for="(type, index) in showLoginType"
            :key="index"
            @click="switchLoginType(type.sign)"
          >
            <i class="icon" v-if="type.icon" :class="type.icon"></i>
            {{ type.text }}
          </div>
        </div>
      </div>
      <div class="login-policy" v-if="!$bowser.mobile">
        未注册手机短信验证成功后将自动注册为馥高会员，注册即视为同意馥高<a
          href="javascrip:void(0);"
          >服务条款</a
        >
      </div>
    </div>
  </div>
</template>

<script>
// 几种form对应key
// login,wx-login,password-login,register,reset-password

import loginForm from "./_form";
// import RegisterForm from "./_register";
import WxScan from "./_scan";
import passwordForm from "./_password-form";
import resetPasswordForm from "./_reset-password";
export default {
  name: "c_login-form",
  components: {
    loginForm,
    // RegisterForm,
    WxScan,
    passwordForm,
    resetPasswordForm
  },
  props: {
    theme: {
      type: String,
      default: "login"
    },
    isShowClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const that = this;
    const loginType =
      that.$bowser.mobile && !that.$configs.isInWx
        ? [
            {
              sign: "login",
              text: "手机登录"
            },
            {
              sign: "password-login",
              text: "密码登录"
            }
          ]
        : [
            {
              sign: "login",
              text: "手机登录"
            },
            {
              sign: "wx-login",
              text: "微信登录",
              icon: "icon-wechat --black"
            },
            {
              sign: "password-login",
              text: "密码登录"
            }
          ];
    return {
      title: {
        login: {
          en: "sign in",
          cn: "快捷登录"
        },
        "wx-login": {
          en: "sign in",
          cn: "微信扫码登录"
        },
        "password-login": {
          en: "sign in",
          cn: "密码登录"
        },
        register: {
          en: "sign in",
          cn: "快捷注册"
        },
        "reset-password": {
          en: "reset password",
          cn: "忘记密码"
        }
      },
      bottomLoginType: loginType,
      innerTheme: that.theme
    };
  },
  watch: {
    theme(val) {
      this.innerTheme = val ? val : "login";
    }
  },
  computed: {
    codeUrl() {
      const snsapi = "https://open.weixin.qq.com/connect/oauth2/authorize?";
      const url = snsapi;
      const callback = encodeURIComponent(this.officialAccount.callback);
      return `${url}appid=${this.officialAccount.appid}&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    },
    showLoginType() {
      const that = this;
      let types;
      if (that.innerTheme) {
        types = that.bottomLoginType.filter(
          item => item.sign !== that.innerTheme
        );
      } else {
        types = that.bottomLoginType;
      }
      return types;
    },
    code() {
      return this.$route.query.code;
    }
  },
  methods: {
    onClose() {
      // this.SET_MODAL_TYPE(null);
      this.$emit("onClose", null);
    },
    switchLoginType(loginType) {
      // this.SET_MODAL_TYPE(loginType);
      if (this.$configs.isInWx && loginType === "wx-login") {
        // 微信里的微信登录
        window.location.href = this.codeUrl;
      } else {
        this.$emit("switchLoginForm", loginType);
      }
    },
    showResetForm() {
      this.switchLoginType("reset-password");
    }
  }
};
</script>

<style></style>
