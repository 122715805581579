<template>
  <el-form
    class="login-form-container"
    ref="mobileLoginForm"
    :model="form"
    :rules="rules"
  >
    <el-form-item prop="mobile">
      <el-input
        v-model="form.mobile"
        :placeholder="placeholder.mobile"
      ></el-input>
    </el-form-item>
    <el-form-item prop="validateCode" v-if="isShowSendBt">
      <el-input
        v-model="form.validateCode"
        :placeholder="placeholder.validateCode"
      >
        <validate-code-bt
          :mobile="form.mobile"
          slot="append"
        ></validate-code-bt>
      </el-input>
    </el-form-item>
    <el-form-item class="captcha__item" v-else>
      <el-input
        placeholder="输入验证码"
        @input="onValidate"
        v-model="inputCaptcha"
        maxlength="4"
        minlength="4"
      ></el-input>
      <div class="captcha__text-container" v-if="captcha">
        <img :src="captcha" alt="" class="" />
      </div>
      <p class="captcha__refresh-bt" @click="getCaptcha(true)">
        看不清？点击刷新
      </p>
    </el-form-item>
    <el-form-item v-if="false">
      <validate-bar></validate-bar>
    </el-form-item>
    <el-form-item class="form-submit">
      <el-button type="submit" @click="onSubmit" :loading="requesting">{{
        btText
      }}</el-button>
    </el-form-item>
    <el-form-item class="form-option-item">
      <el-checkbox
        class="option__item"
        v-model="rememberMe"
        v-if="!$bowser.mobile"
        >7天内自动登录</el-checkbox
      >
      <div class="option__item" @click="openResetForm">忘记密码</div>
    </el-form-item>
  </el-form>
</template>

<script>
import validateBar from "./_slider-bar";
import validateCodeBt from "../common/_validate-code-bt";
import { mapActions, mapState } from "vuex";
export default {
  name: "c-login-form",
  components: {
    validateBar,
    validateCodeBt
  },
  data() {
    return {
      form: {
        mobile: "",
        validateCode: ""
      },
      placeholder: {
        mobile: "手机号码",
        validateCode: "短信验证码"
      },
      rememberMe: false,
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            pattern: /^0{0,1}(1)[0-9]{10}$/,
            message: "手机号码格式不正确",
            trigger: "blur"
          }
        ],
        validateCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur"
          }
        ]
      },
      requesting: false,
      isShowSendBt: true,
      captcha: null,
      inputCaptcha: ""
    };
  },
  computed: {
    ...mapState("shop", ["accountModalType"]),
    btText() {
      return this.accountModalType
        ? this.accountModalType === "login"
          ? "登录"
          : "注册"
        : "登录";
    }
  },
  mounted() {
    if (!this.isShowSendBt) {
      this.getCaptcha();
    }
  },
  methods: {
    ...mapActions("user", ["login"]),
    onSubmit() {
      const that = this;
      that.$refs.mobileLoginForm.validate(valid => {
        if (valid) {
          that.requesting = true;
          that
            .login({
              type: "mobile",
              mobile: that.form.mobile,
              code: that.form.validateCode,
              rememberMe: that.rememberMe
            })
            .then(res => {
              const data = res.data;
              that.afterLogin(data);
              that.$emit("close");
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              that.requesting = false;
            });
        }
      });
    },
    getCaptcha(refresh) {
      const that = this;
      const url = refresh ? "/api/default/captcha" : "/api/default/captcha";
      that.$request.get(url).then(res => {
        that.captcha = res.data;
      });
    },
    onValidate(val) {
      const that = this;
      if (val.length === 4) {
        // 验证验证码
        that.$request
          .post("/api/default/validate-captcha", {
            code: val
          })
          .then(res => {
            console.log(res);
          });
      }
    },
    openResetForm() {
      this.$emit("showResetForm");
    }
  }
};
</script>

<style></style>
