<template>
  <div class="page-login-container" ref="wrapper">
    <login-c
      :theme="theme"
      @switchLoginForm="switchLogin"
      :isShowClose="false"
    ></login-c>
  </div>
</template>

<script>
import loginC from "./_login-form";
export default {
  name: "real-page-login",
  components: {
    loginC
  },
  data() {
    return {
      theme: "login",
      type: this.$route.query.type
    };
  },
  mounted() {
    const that = this;
    if (this.type === "op") {
      this.theme = "wx-login";
    }
    that.heightFullScreen(that.$refs.wrapper, false);
  },
  methods: {
    switchLogin(type) {
      this.theme = type;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-login-container {
  overflow: hidden;
  position: relative;
  .login-page-container {
    position: absolute;
    top: 0;
    height: 100%;
    ::v-deep .form-container {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
