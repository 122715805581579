<template>
  <el-form class="login-form-container">
    <el-form-item prop="mobile">
      <el-input
        v-model="form.mobile"
        :placeholder="placeholder.mobile"
      ></el-input>
    </el-form-item>
    <el-form-item prop="validateCode">
      <el-input
        v-model="form.validateCode"
        :placeholder="placeholder.validateCode"
      >
        <validate-code-bt
          :mobile="form.mobile"
          slot="append"
        ></validate-code-bt>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        type="password"
        v-model="form.password"
        :placeholder="placeholder.password"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        type="password"
        v-model="form.repeatPassword"
        :placeholder="placeholder.repeatPassword"
      ></el-input>
    </el-form-item>
    <el-form-item class="form-submit">
      <el-button type="submit" @click="onSubmit" :loading="requesting"
        >登录</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import validateCodeBt from "../common/_validate-code-bt";
export default {
  name: "c-reset-password-form",
  components: {
    validateCodeBt
  },
  data() {
    return {
      form: {
        mobile: "",
        password: "",
        repeatPassword: "",
        validateCode: ""
      },
      placeholder: {
        mobile: "手机号码",
        password: "密码",
        repeatPassword: "重复密码",
        validateCode: "验证码"
      },
      requesting: false
    };
  },
  methods: {
    onSubmit() {}
  }
};
</script>

<style></style>
