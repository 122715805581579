<template>
  <div class="qr-box">
    <iframe
      class="qr-iframe"
      :src="src"
      scrolling="no"
      frameBorder="0"
      allowTransparency="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "c-wx-open-platform-qr",
  props: {
    code: {
      type: String,
      default: ""
    }
  },
  data() {
    const that = this;
    return {
      appid: that.openPlatform.appid,
      scope: "snsapi_login",
      redirectUri: that.openPlatform.callback,
      state: new Date().getTime(),
      selfRedirect: false,
      style: "black",
      href:
        "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxle2Rpc3BsYXk6bm9uZTt9LmltcG93ZXJCb3ggLndycF9jb2Rle2ZvbnQtc2l6ZTowO30uaW1wb3dlckJveCAucXJjb2Rle3dpZHRoOjIzMHB4O21hcmdpbi10b3A6MDtoZWlnaHQ6MjMwcHg7fS5pbXBvd2VyQm94IC5pbmZve3dpZHRoOjIzMHB4O21hcmdpbi10b3A6N3B4O30uaW1wb3dlckJveCAuc3RhdHVze3BhZGRpbmc6MDtmb250LXNpemU6MTRweDtjb2xvcjojMDAwO2xpbmUtaGVpZ2h0OjEuNTt9LmltcG93ZXJCb3ggLmljb24zOF9tc2d7d2lkdGg6MTlweDtoZWlnaHQ6MTlweDt9LmltcG93ZXJCb3ggLmljb24zOF9tc2cuc3VjY3tiYWNrZ3JvdW5kLXNpemU6MTlweDtiYWNrZ3JvdW5kLXBvc2l0aW9uOjAgLTIzcHg7fS5pbXBvd2VyQm94IC5pY29uMzhfbXNnLndhcm57YmFja2dyb3VuZC1zaXplOjE5cHg7YmFja2dyb3VuZC1wb3NpdGlvbjowIC00M3B4O30=",
      loginType: "jssdk"
    };
  },
  computed: {
    src() {
      const callback = encodeURIComponent(this.redirectUri);
      const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${this.appid}&scope=${this.scope}&redirect_uri=${callback}&state=${this.state}&login_type=${this.loginType}&self_redirect=${this.selfRedirect}&style=${this.style}&href=${this.href}`;
      return url;
    }
  },
  watch: {
    code(val) {
      console.log(val);
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.qr-iframe {
  width: 100%;
  height: 280px;
}
</style>
