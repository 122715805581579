<template>
  <el-form
    class="login-form-container"
    ref="mobileLoginForm"
    :model="form"
    :rules="rules"
  >
    <el-form-item prop="mobile">
      <el-input
        v-model="form.mobile"
        :placeholder="placeholder.mobile"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        type="password"
        v-model="form.password"
        :placeholder="placeholder.password"
      ></el-input>
    </el-form-item>
    <el-form-item class="form-submit">
      <el-button type="submit" @click="onSubmit" :loading="requesting"
        >登录</el-button
      >
    </el-form-item>
    <el-form-item class="form-option-item">
      <el-checkbox
        class="option__item"
        v-model="rememberMe"
        v-if="!$bowser.mobile"
        >7天内自动登录</el-checkbox
      >
      <div class="option__item">忘记密码</div>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "c-password_form",
  data() {
    return {
      form: {
        mobile: "",
        password: ""
      },
      placeholder: {
        mobile: "手机号码",
        password: "密码"
      },
      rememberMe: false,
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            pattern: /^0{0,1}(1)[0-9]{10}$/,
            message: "手机号码格式不正确",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ]
      },
      requesting: false
    };
  },
  methods: {
    ...mapActions("user", ["login"]),
    onSubmit() {
      const that = this;
      that.$refs.mobileLoginForm.validate(valid => {
        if (valid) {
          that.requesting = true;
          that
            .login({
              type: "password",
              mobile: that.form.mobile,
              password: that.form.password,
              rememberMe: that.rememberMe
            })
            .then(res => {
              const data = res.data;
              that.afterLogin(data);
              that.$emit("close");
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              that.requesting = false;
            });
        }
      });
    }
  }
};
</script>

<style></style>
