<template>
  <div class="validate-bar"></div>
</template>

<script>
export default {
  name: "c-validate-bar"
};
</script>

<style lang="scss" scoped>
.validate-bar {
  width: 100%;
  height: 36px;
  background-color: #e6e6e6;
}
</style>
