<template>
  <el-button @click="getCode" :loading="requesting" :disabled="disabled">
    <template v-if="disabled || countRest > 0"
      >{{ countRest }}s后重新获取</template
    >
    <template v-else>{{ requesting ? "发送中..." : "获取验证码" }}</template>
  </el-button>
</template>

<script>
const limit = 60;
import { isMobile } from "@/libs/tools";
import { mapMutations, mapState } from "vuex";
export default {
  name: "c-validate-code-bt",
  props: {
    mobile: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      disabled: false,
      requesting: false,
      timer: null
    };
  },
  computed: {
    ...mapState("shop", ["countRest"]),
    isMobileEmpty() {
      return this.mobile === null || this.mobile === "";
    },
    isMobileIllegal() {
      return !isMobile(this.mobile);
    }
  },
  methods: {
    ...mapMutations("shop", ["SET_COUNTDOWN", "COUNT_REDUCE"]),
    getCode() {
      const that = this;
      if (!that.requesting) {
        if (that.isMobileEmpty) {
          that.$message({
            message: "请填写手机号码",
            type: "error"
          });
        } else if (that.isMobileIllegal) {
          that.$message({
            message: "请输入有效手机号码",
            type: "error"
          });
        } else {
          that.loading = true;
          that.$request
            .post("/api/default/login-code", {
              mobile: that.mobile
            })
            .then(() => {
              // 开始倒计时
              that.setcountdown();
            })
            .finally(() => {
              that.loading = false;
            });
        }
      }
    },
    setcountdown() {
      const that = this;
      that.SET_COUNTDOWN(limit);
      that.disabled = true;
      that.countdown();
    },
    countdown() {
      const that = this;
      if (that.requesting) {
        return;
      }
      that.COUNT_REDUCE();
      if (that.countRest <= 0) {
        that.disabled = false;
        clearTimeout(that.timer);
      } else {
        that.timer = setTimeout(function() {
          that.countdown();
        }, 1000);
      }
    }
  }
};
</script>

<style></style>
