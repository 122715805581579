<template>
  <div class="wx-scan-container">
    <div class="wx-qr-box qr-scan-wrapper">
      <div class="qr-scan-hint" v-if="code !== ''">
        <template v-if="requesting">
          <p>正在登录...</p>
        </template>
        <template v-else>
          <p v-if="isCorrectLogin">登录成功，跳转中...</p>
          <p v-else>登录失败：{{ error }}</p>
        </template>
      </div>
      <wx-open-platform-qr :code="code"></wx-open-platform-qr>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import wxOpenPlatformQr from "@/components/wx-open-platform-login";
export default {
  // inheritAttrs: false,
  name: "c-wx-scan-form",
  props: {
    code: {
      type: String,
      default: ""
    }
  },
  components: {
    wxOpenPlatformQr
  },
  data() {
    return {
      requesting: this.code !== "" ? true : false,
      isCorrectLogin: false,
      error: ""
    };
  },
  created() {},
  mounted() {
    const that = this;
    if (that.code) {
      that.requesting = true;
      that
        .login({
          type: "openPlatform",
          code: that.code
        })
        .then(res => {
          that.isCorrectLogin = true;
          const data = res.data;
          that.afterLogin(data);
        })
        .catch(err => {
          that.error = err;
          console.log(err);
        })
        .finally(() => {
          that.requesting = false;
        });
    }
  },
  methods: {
    ...mapActions("user", ["login"])
  }
};
</script>

<style lang="scss" scoped>
.qr-scan-wrapper {
  position: relative;
  .qr-scan-hint {
    width: 230px;
    height: 230px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    p {
      font-size: 14px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
}
</style>
